/* eslint no-var: 0 */
/* eslint eqeqeq: 0 */
import $ from 'jquery'
import { createChart, getChartData, parseZoneString } from '../../charts/BulletChart'
import { showNotification } from '@mantine/notifications'

if (!window.dataViewAPI) {
  window.dataViewAPI = {}

  window.dataViewAPI.get = function (dataView) {
    const $dataView = $(dataView)
    return $dataView.data('api')
  }

  window.dataViewAPI.isReady = function (dataView) {
    const $dataView = $(dataView)
    return typeof $dataView.data('api') !== 'undefined'
  }

  window.dataViewAPI.ready = function (dataView, callback) {
    if (window.dataViewApi.isReady(dataView)) {
      callback()
    } else {
      const $dataView = $(dataView)
      if (typeof $dataView.data('callbacks') === 'undefined') {
        $dataView.data('callbacks', [])
      }
      const callbacks = $dataView.data('callbacks')
      callbacks.push(callback)
      $dataView.data('callbacks', callbacks)
    }
  }

  if (typeof $.fn.serializeObject !== 'function') {
    $.fn.serializeObject = function () {
      const o = {}
      const a = this.serializeArray()
      $.each(a, function () {
        if (o[this.name] !== undefined) {
          if (!o[this.name].push) {
            o[this.name] = [o[this.name]]
          }
          o[this.name].push(this.value || '')
        } else {
          o[this.name] = this.value || ''
        }
      })
      return o
    }
  }

  if (typeof $.fn.highlight !== 'function') {
    $.extend({
      highlight (node, re, nodeName, className) {
        if (node.nodeType === 3) {
          const match = node.data.match(re)
          if (match) {
            const highlight = document.createElement(nodeName || 'span')
            highlight.className = className || 'highlight'
            const wordNode = node.splitText(match.index)
            wordNode.splitText(match[0].length)
            const wordClone = wordNode.cloneNode(true)
            highlight.appendChild(wordClone)
            wordNode.parentNode.replaceChild(highlight, wordNode)
            return 1
          }
        } else if ((node.nodeType === 1 && node.childNodes) &&
                          !/(script|style)/i.test(node.tagName) &&
                          !(node.tagName === nodeName.toUpperCase() && node.className === className)) {
          for (let i = 0; i < node.childNodes.length; i++) {
            i += $.highlight(node.childNodes[i], re, nodeName, className)
          }
        }
        return 0
      }
    })

    $.fn.unhighlight = function (options) {
      const settings = { className: 'highlight', element: 'span' }
      $.extend(settings, options)

      return this.find(settings.element + '.' + settings.className).each(function () {
        const parent = this.parentNode
        parent.replaceChild(this.firstChild, this)
        parent.normalize()
      }).end()
    }

    $.fn.highlight = function (words, options) {
      const settings = { className: 'highlight', element: 'span', caseSensitive: false, wordsOnly: false }
      $.extend(settings, options)

      if (words.constructor === String) {
        words = [words]
      }

      words = $.grep(words, function (word) {
        return word != ''
      })

      words = $.map(words, function (word) {
        return word.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
      })

      if (words.length == 0) {
        return this
      }

      var flag = settings.caseSensitive ? '' : 'i'
      var pattern = '(' + words.join('|') + ')'
      if (settings.wordsOnly) {
        pattern = '\\b' + pattern + '\\b'
      }
      var re = new RegExp(pattern, flag)

      return this.each(function () {
        $.highlight(this, re, settings.element, settings.className)
      })
    }
  }
}

$(document).ready(function () {
  const expandedArray = []

  $('.data-view table tr th a').click(function (event) {
    event.stopPropagation()
  })

  $('.collapser').click(function () {
    var collapserId = $(this).parent('th').attr('data-collapser')
    var collapsedElements = $('tr').find("[data-collapser-parent='" + collapserId + "']")

    if ((collapserId in expandedArray) && (expandedArray[collapserId] === true)) {
      $(this).html('<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>')
      expandedArray[collapserId] = false
      $(collapsedElements).each(function () {
        $(this).addClass('hidden')
      })
    } else {
      $(this).html('<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>')
      expandedArray[collapserId] = true
      $(collapsedElements).each(function () {
        $(this).removeClass('hidden')
      })
    }
  })

  $('div.data-view-init', undefined).each(function (index) {
    var $dataView = $(this)
    var defaultLimit = $('.data-view-init').attr('data-default-limit')

    if ($dataView.hasClass('loaded')) { return }
    $dataView.addClass('loaded')

    var indexNumber = index + 1

    var $dataViewTableBody = $dataView.find('table tbody')
    var $dataViewTable = $dataView.find('table')
    var $preloader = $dataView.find('.preloader')
    var emptyMessage = 'No items found'
    if (typeof $dataView.data('empty-message') !== 'undefined') {
      emptyMessage = $dataView.data('emptyMessage')
    }

    var defaultInputs = getParsedInputs()
    var disableHashChangePopulateOnce = false

    // ajax request time out settings
    var populateTimeOutInterval = 500
    var populateTimeOut = 0
    var populateXHR

    installOneTimeBehaviors($dataView)

    $dataView.data('api', {
      populate: populateData,
      hideBulkActions: hideBulkActions,
      showBulkActions: showBulkActions
    })

    if (typeof $dataView.data('callbacks') !== 'undefined') {
      var callbacks = $dataView.data('callbacks')
      for (const index in callbacks) {
        callbacks[index]()
      }
      $dataView.data('callbacks', [])
    }

    function isActive () {
      return $('.data-view').length != 0;
    }

    function getSearchableColumns () {
      var searchableColumns = {}
      $('table thead tr th.searchable', $dataView).each(function () {
        var $column = $(this)
        var column = $column.data('column')
        searchableColumns[column] = $column.text()
      })
      return searchableColumns
    }

    function getSearchedColumns () {
      var searchableColumns = getSearchableColumns()
      var searchedColumns = {}
      var columnInput = $dataView.find('.search-wrapper .search-select')

      if (typeof columnInput === 'undefined') {
        return searchedColumns
      }

      var columnInputValue = columnInput.val()

      if (columnInputValue == 'all') {
        return searchableColumns
      }

      searchedColumns[columnInputValue] = searchableColumns[columnInputValue]
      return searchedColumns
    }

    function hideBulkActions () {
      $dataView.find('.bulk-action-inputs').hide()
    }

    function showBulkActions () {
      $dataView.find('.bulk-action-inputs', $dataView).show()
    }

    function resizePreloader () {
      $preloader.height($dataViewTable.height())
    }

    function showPreloader () {
      resizePreloader()
      $preloader.fadeIn(400)
    }

    function hidePreloader () {
      $preloader.fadeOut(400)
    }

    function setOffset (offset) {
      $dataView.find('input.pageDataOffset').val(offset)
    }

    function setLimit (limit) {
      $dataView.find('input.pageDataLimit').text(limit)
    }

    function installOnPopulateBehaviors ($ctx) {
      $('.pager-wrapper .page-options .page-option', $ctx).click(function () {
        var offset = $(this).data('offset')
        setOffset(offset)
        populateData()
      })

      $('.bulk-actions', $ctx).click(function () {
        var action = $(this).attr('data-action')

        var method = 'post'
        var customMethod = $(this).attr('data-method')
        if (customMethod) {
          method = customMethod
        }

        var vars = {}

        $(this.attributes).each(function (i, attr) {
          if (/^data-rq-.*$/.test(attr.name)) {
            var name = attr.name.substr('data-rq-'.length)
            vars[name] = attr.value
          }
        })

        var ids = []

        $(this).parents('.data-view:first').find('.select_item').filter(':checked').each(function (i, e) {
          ids.push($(e).val())
        })

        var form = $('<form />', { action, method })

        for (var key in vars) {
          var value = vars[key]
          form.append($('<input/>', { type: 'hidden', name: key, value: value }))
        }

        $('body').append(form)
        $(ids).each(function (i, id) {
          var input = $('<input/>', { type: 'hidden', name: 'selected[]' })
          input.val(id)
          form.append(input)
        })

        $(form).submit()
      })
    }

    function installOneTimeBehaviors ($ctx) {
      $('table thead tr th.sortable', $ctx).click(function () {
        var $orderColumn = $dataView.find('input.orderColumn')
        var $orderDirection = $dataView.find('input.orderDirection')

        var column = $(this).data('column')
        var currentColumn = $orderColumn.val()
        var currentDirection = $orderDirection.val()

        if (currentColumn == column) {
          if (currentDirection == 'DESC') {
            $orderDirection.val('ASC')
          } else {
            $orderDirection.val('DESC')
          }
        }
        if (currentDirection != 'DESC' && currentDirection != 'ASC') {
          $orderDirection.val('ASC')
        }

        $orderColumn.val(column)

        populateData()
      })

      $('.search-wrapper .search', $ctx).bind('keypress', function (event) {
        if (event.which == 13) {
          event.preventDefault()
          setOffset(0)
          setLimit(0)
          populateData()
        }
      })

      var keyUpTimeout = 0
      $('.search-wrapper .search', $ctx).bind('keyup', function (event) {
        if (event.which !== 9 && event.which !== 16 && event.which !== 18) {
          if (keyUpTimeout) { clearTimeout(keyUpTimeout) }
          keyUpTimeout = setTimeout(function () {
            setOffset(0)
            setLimit(0)
            populateData()
          }, 500)
        }
      })

      $('.search-wrapper .search-select', $ctx).change(function () {
        setOffset(0)
        setLimit(0)
        populateData()
      })

      $('.limit-wrapper .page-limit', $ctx).change(function () {
        var limit = $(this).val()
        setLimit(limit)
        $.each($dataView.find('.limit-wrapper .page-limit'), function () {
          $(this).val(limit)
          if ($.uniform) {
            $.uniform.update($(this))
          }
        })

        $dataView.find('input.pageDataLimit').val(limit)
        populateData()
      })

      window.addEventListener('applicant:updated', () => {
        populateData()
      })

      $('.data-view-trigger-populate', $ctx).on('populate', function () {
        populateData()
      })

      $('.data-view-click-populate', $ctx).click(function () {
        populateData()
      })

      $('.data-view-change-populate', $ctx).change(function () {
        populateData()
      })

      $('.data-view-blur-populate', $ctx).blur(function () {
        populateData()
      })

      $('table input.select_all', $ctx).click(function (ev) {
        var chk = $('input.select_item', $(ev.currentTarget).parents('table:first')).prop('checked', $(ev.currentTarget).prop('checked'))
        $.uniform.update(chk)
      })

      $('.reset-sorting-wrapper .reset-sorting', $ctx).click(function () {
        setLimit(defaultLimit)
        $dataView.find('input.pageDataLimit').val(defaultLimit)
        setOffset(0)
        $dataView.find('input.pageDataOffset').val(0)
        $dataView.find('input.orderColumn').val('')
        $dataView.find('input.orderDirection').val('')
        $dataView.find('input.search').val('')
        $dataView.find('select.search-select').val('all')
        removeHash()
        populateData(true)
      })

      $(window).resize(function () {
        resizePreloader()
      }).resize()

      $dataView.on('populate', function () {
        resizePreloader()
      })

      $(window).on('hashchange', function () {
        if (!disableHashChangePopulateOnce) {
          var valuesChanged = depopulateHash()
          if (valuesChanged) {
            populateData(true)
          }
        }
        disableHashChangePopulateOnce = false
      })

      depopulateHash()
      populateData(true)
      $dataView.addClass('table-mode')

      $dataView.find('table thead tr th, table tbody tr td').show()

      $dataView.find('table tbody tr.empty-message td').show()

      $dataView.find('.mode-ignore').show()
    }

    function getSearchString () {
      var $searchInput = $dataView.find('.search-wrapper .search-input .search')
      if (typeof $searchInput === 'undefined') {
        return ''
      }
      return $searchInput.val()
    }

    function getIndexNumber (index) {
      var indexStart = index.lastIndexOf('|')
      return index.substring(indexStart + 1)
    }

    function getIndexValue (index) {
      var indexStart = index.lastIndexOf('|')
      return index.substring(0, indexStart)
    }

    function formatHashInputName (name) {
      if (name.substring(0, 9) == 'viewData[') {
        return '!' + name.substring(9).slice(0, -1)
      }
      return name
    }

    // This gets all the inputs in the data-view and filters out inputs that are not filters
    function getFilteredInputs () {
      var $inputs = $dataView.find('select,textarea,input')
      var $filteredInputs = {}
      var length = $inputs.length
      for (var key = 0; key < length; key++) {
        var $this = $($inputs[key])
        if ($this.attr('name') == undefined) { continue }
        if ($this.attr('name') == 'viewData[key]') { continue }
        if ($this.hasClass('data-view-ignore')) { continue }
        $filteredInputs[$this.attr('name')] = $this
      }
      return $filteredInputs
    }

    // Takes the filtered inputs and creats an object of parsed hash name|index => value
    function getParsedInputs () {
      var $inputs = getFilteredInputs()
      var hashPieces = {}
      for (var key in $inputs) {
        var $this = $($inputs[key])
        hashPieces[formatHashInputName($this.attr('name')) + '|' + indexNumber] = $this.val()
      }
      return hashPieces
    }

    // Gets what is in the hash and parses it back into the array of name|index => value
    function getParsedHash () {
      var hashPieces = {}
      var currentHash = window.location.hash.substring(1)
      if (currentHash.length > 0) {
        var hashArray = currentHash.split('&')
        var length = hashArray.length
        for (var key = 0; key < length; key++) {
          var objectPieces = hashArray[key].split('=')
          var indexNum = getIndexNumber(objectPieces[0])
          var indexValue = getIndexValue(objectPieces[0])
          hashPieces[decodeURIComponent(indexValue) + '|' + indexNum] = decodeURIComponent(objectPieces[1])
        }
      }
      return hashPieces
    }

    function removeHash () {
      window.location.hash = ''
    }

    // takes the filter inputs and puts them into the hash
    function populateHash () {
      var inputs = getFilteredInputs()
      var parsedInputs = getParsedInputs()
      var parsedHash = getParsedHash()

      // remove all elements related to this dataviees index they will get repopulated later
      $.each(parsedHash, function (index) {
        var indexNum = getIndexNumber(index)
        if (indexNumber == parseInt(indexNum)) {
          delete parsedHash[index]
        }
      })

      // this will be the new hash
      var hashPieces = []

      // add all the hash pieces from other data-views that should stay
      for (const index in parsedHash) {
        const value = parsedHash[index]
        const indexNum = getIndexNumber(index)
        const indexValue = getIndexValue(index)
        hashPieces.push(encodeURIComponent(indexValue) + '|' + indexNum + '=' + encodeURIComponent(value))
      }

      for (const index in parsedInputs) {
        const value = parsedInputs[index]
        const indexNum = getIndexNumber(index)
        const indexValue = getIndexValue(index)
        let forcePopulate = false

        // if it is a checkbox and it is unchecked then we don't want to include it
        if (typeof inputs[indexValue] !== 'undefined') {
          var $input = $(inputs[indexValue])
          if (typeof $input.attr('type') !== 'undefined' && $input.attr('type') == 'checkbox') {
            if ($input.is(':checked') == false) {
              continue
            } else {
              forcePopulate = true
            }
          }
        }

        // if it hasn't changed then don't include it
        if (!forcePopulate && typeof defaultInputs[index] !== 'undefined' && defaultInputs[index] == value) {
          continue
        }

        hashPieces.push(encodeURIComponent(indexValue) + '|' + indexNum + '=' + encodeURIComponent(value))
      }
      var hash = hashPieces.join('&')
      disableHashChangePopulateOnce = true
      window.location.hash = hash
    }

    // Gets what is in the hash and populates the filtered inputs with values
    function depopulateHash () {
      var parsedHash = getParsedHash()
      var valuesChanged = false
      var $inputs = getFilteredInputs()
      // uncheck all filters - i don't like this but what you gonna do eh
      for (var key in $inputs) {
        var $this = $($inputs[key])
        if (typeof $this.attr('type') !== 'undefined' && $this.attr('type') == 'checkbox') {
          $this.prop('checked', false)
        }
      }

      $.each(parsedHash, function (index, val) {
        var indexValue = getIndexValue(index)

        if (indexValue.substring(0, 1) == '!') {
          indexValue = 'viewData[' + indexValue.substring(1) + ']'
        }

        var indexNum = getIndexNumber(index)

        if (indexNumber != parseInt(indexNum)) { return }

        if (typeof $inputs[indexValue] !== 'undefined') {
          var $this = $($inputs[indexValue])

          if (typeof $this.attr('type') !== 'undefined' && $this.attr('type') == 'checkbox') {
            $this.prop('checked', true)
            valuesChanged = true
          } else {
            if ($this.val() != val) {
              valuesChanged = true
            }
            $this.val(val)
          }

          $this.trigger('update')
        }
      })
      return valuesChanged
    }

    function showRowMessage (message) {
      $dataViewTableBody.empty()
      var $emptyMessage = $('<td></td>')
      $emptyMessage.attr('colspan', '1000')
      $emptyMessage.html(message)
      var $emptyMessageWrap = $('<tr></tr>')
      $emptyMessageWrap.addClass('empty-message')
      $emptyMessageWrap.append($emptyMessage)
      $dataViewTableBody.append($emptyMessageWrap)
    }

    function populateData (disablePopulateHash) {
      if (!isActive()) {
        return
      }

      $dataView.trigger('prepopulate')

      if (typeof disablePopulateHash === 'undefined' || (typeof disablePopulateHash !== 'undefined' && disablePopulateHash != true)) {
        populateHash()
      }

      showPreloader()

      if (populateTimeOut) { clearTimeout(populateTimeOut) }
      if (populateXHR) { populateXHR.abort() }

      populateTimeOut = setTimeout(function () {
        var data = $dataView.find('select,textarea,input').serializeObject()
        populateXHR = $.post('/data/fetch', data,
          function (response) {
            populateXHR = null
            $dataViewTableBody.empty()

            if (response.error) {
              showNotification({
                message: response.message,
                color: 'red',
                autoClose: true
              })
              hidePreloader()
              return
            }

            if (response.data.length !== undefined && response.data.length !== 0) {
              var length = response.data.length

              for (var key = 0; key < length; key++) {
                var $row = createRow(response.data[key])
                $dataViewTableBody.append($row)
              }
            } else {
              showRowMessage(emptyMessage)
            }
            updateCollapsers()
            addContentEditable()
            reflowFoundation()
            $dataView.find('.pager-wrapper').each(function () {
              var $pageOptions = $(this)
              $pageOptions.empty()
              var pager = createPageOptions(response.limit, response.offset, response.totalFiltered)
              $pageOptions.append(pager)
            })
            var itemsStart = parseInt(response.offset) + 1
            var itemsEnd = parseInt(response.offset) + parseInt(response.limit)
            if (itemsEnd == 0 || response.totalFiltered < itemsEnd) {
              itemsEnd = response.totalFiltered
            }
            if (response.totalFiltered == 0) {
              itemsStart = 0
            }

            if (response.totalFiltered == response.total) {
              $dataView.find('.page-totals').html(itemsStart + '&nbsp;to&nbsp;' + itemsEnd + '&nbsp;of&nbsp;' + response.total + '&nbsp;Items')
            } else {
              $dataView.find('.page-totals').html(itemsStart + '&nbsp;to&nbsp;' + itemsEnd + '&nbsp;of&nbsp;' + response.totalFiltered + '&nbsp;Filtered&nbsp;/&nbsp;' + response.total + '&nbsp;Total Items')
            }
            hidePreloader()
            installOnPopulateBehaviors($dataView)
            highlightRows()
            $dataView.trigger('populate')
            $(document).trigger('dataview:loaded')
          }, 'json').fail(function (errorResponse) {
          if (errorResponse.statusText != 'abort' && errorResponse.statusText != 'OK') {
            showRowMessage('Failed to load data, please contact your system administrator if problems persist.')
            hidePreloader()
          }
          hidePreloader()
          $(document).trigger('dataview:loaded')
        })
      }, populateTimeOutInterval)
    }

    $(document).on('dataview:loaded', function () {
      if (document.querySelectorAll('canvas.bulletchart')?.length) {
        for (const ctx of document.querySelectorAll('canvas.bulletchart')) {
          const data = getChartData(parseZoneString(ctx.dataset.values), '', ctx.dataset.score)
          createChart(ctx, data)
        }
      }
    })

    $(document).on('change', '.data-view thead input[name="select-all"]', function () {
      $('input.row-multi-selector').prop('checked', $(this).prop('checked'))
      $('.data-view').trigger('row:highlight', [$(this).prop('checked')])
    })

    $(document).on('click', 'table tbody .row-multi-selector', function (event) {
      const targetTag = $(event.target).prop('tagName')
      let value = true
      let rowId = $(event.target).attr('data-row-id')

      if (targetTag === 'INPUT') {
        value = $(event.target).prop('checked')
        if (!value) {
          $('.data-view table thead input[name="select-all"]').prop('checked', false)
        }
      } else if (targetTag !== 'TR') {
        value = !$(event.target).parents('tr[data-row-id]').hasClass('highlight-row')
        rowId = $(event.target).parents('tr[data-row-id]').attr('data-row-id')
      }

      $('table tbody tr[data-row-id="' + rowId + '"]').trigger('row:highlight', [value, rowId])
    })

    $(document).on('row:highlight', {
      highlight: true,
      rowId: '-1'
    }, function (event, highlight, rowId) {
      let selector = ''

      if (rowId) {
        selector = '.data-view table tbody tr[data-row-id="' + rowId + '"]'
      } else {
        selector = '.data-view table tbody tr[data-row-id]'
      }

      if (highlight) {
        $('div#bulk-actions').removeClass('disabled')
        $(selector).addClass('highlight-row')
        $(selector).each(function (index, element) {
          const id = $(element).attr('data-row-id')

          if ($('input[data-rowid="' + id + '"]').length === 0) {
            $dataView.prepend('<input type="hidden" class="highlightedRows" name="viewData[highlightedRows]" data-rowid="' + id + '" value="' + id + '" />')
          }
        })
      } else {
        $(selector).removeClass('highlight-row')
        $(selector).each(function (index, element) {
          const id = $(element).attr('data-row-id')
          $dataView.find('input[data-rowid="' + id + '"]').remove()
        })
        if ($('input.highlightedRows').length === 0) {
          $('div#bulk-actions').addClass('disabled')
        }
      }
      if ($('input.highlightedRows').length > 0) {
        $('.data-view table thead th[data-column="row-selector"] span').html('(' + $('input.highlightedRows').length + ')')
      } else {
        $('.data-view table thead th[data-column="row-selector"] span').html('')
      }
    })

    function highlightRows () {
      $('input.highlightedRows').each(function (index, element) {
        const id = $(element).attr('data-rowid')
        $('.data-view table tbody tr[data-row-id="' + id + '"]').addClass('highlight-row').find('input.row-multi-selector').prop('checked', 'checked')
      })
    }

    $(document).on('click', 'a#invite_applicants.bulk-action-listener', function () {
      const selectedRows = $dataView.children('.highlightedRows')

      if (selectedRows.length === 0) {
        showNotification({
          message: 'Please select at least one row to perform this action.',
          color: 'red',
          autoClose: true
        })
        return
      }

      const result = confirm('Are you sure you would like to invite ' + selectedRows.length + ' Applicants?')

      if (result) {
        const applicantIds = []
        for (let i = 0; i < selectedRows.length; i++) {
          applicantIds.push(selectedRows[i].value)

          const selector = $('table tr[data-row-id="' + selectedRows[i].value + '"]')
          if (selector.length === 1) {
            selector.removeClass('highlight-row')
          }
        }

        const url = $(this).data('redirect-url')
        const reload = $(this).data('reload')
        $.ajax({
          url: url,
          type: 'POST',
          dataType: 'json',
          data: { applicantIds },
          success: function () {
            showNotification({
              message: 'Operation completed successfully',
              color: 'green',
              autoClose: true
            })

            if (reload === true) {
              location.reload()
            }
          },
          error: function () {
            location.reload()
          }
        })
      }
    })

    function createRow (rowItem) {
      var $tr = $('<tr></tr>')

      if (rowItem.rowAttributes) {
        $.each(rowItem.rowAttributes, function (attribute, value) {
          if (attribute == 'style' && typeof value === 'object') {
            $.each(value, function (property, rule) {
              $tr.css(property, rule)
            })
          } else if (attribute == 'onclick') {
            $tr.addClass('clickable-table-row')
            $tr.attr('data-redirect-url', value)
            $tr.css('cursor', 'pointer')
          } else {
            $tr.attr(attribute, value)
          }
        })
      }

      if (rowItem.values) {
        $.each(rowItem.values, function (columnKey, value) {
          var $td = $('<td></td>')
          $td.html(value)
          var attributes = rowItem.cellAttributes[columnKey]
          if (attributes) {
            $.each(attributes, function (attribute, value) {
              if (attribute == 'style' && typeof value === 'object') {
                $.each(value, function (property, rule) {
                  $td.css(property, rule)
                })
                return
              }
              $td.attr(attribute, value)
            })
          }

          var searchString = getSearchString()

          if (
            searchString != '' &&
            $td.data('searched') != undefined &&
            $td.data('searched') == true &&
            Object.prototype.hasOwnProperty.call(getSearchedColumns(), columnKey)
          ) {
            $td.highlight(searchString)
            $('.highlight', $td).css({ color: '#2DB1FF', fontWeight: 'bold', pointerEvents: 'none' })
          }
          $tr.append($td)
        })
      }
      return $tr
    }

    function createPageOptions (limit, offset, total) {
      var totalPages = 1
      var currentPage = 1
      if (limit > 0 && total > 0) {
        totalPages = Math.ceil(total / limit)
        currentPage = Math.ceil(offset / limit) + 1
      }

      $('.page-limit option').each(function () {
        if ($(this).val() === limit) {
          $(this).prop('selected', true)
        }
      })

      $('.page-limit').each(function () {
        $(this).show()
      })

      var pageOptions = getPageOptions(currentPage, 3, 2, 2, totalPages, '&hellip;')

      var $pageOptions = $('<ul></ul>')
      $pageOptions.addClass('pagination page-options')
      var $pagePrevious = $('<li class="arrow"></li>')
      $pagePrevious.html('<a>' + '&laquo;' + '</a>')
      var previousOffset = (currentPage * limit) - (2 * limit)
      if (previousOffset >= 0) {
        $pagePrevious.data('offset', previousOffset)
        $pagePrevious.addClass('page-option')
      }
      $pageOptions.append($pagePrevious)
      $.each(pageOptions, function (index, value) {
        var $pageOption = $('<li></li>')
        $pageOption.html('<a>' + value + '</a>')
        $pageOption.data('offset', (value * limit) - limit)
        if (value != '&hellip;' && value == currentPage) {
          $pageOption.addClass('current')
        } else if (value != '&hellip;') {
          $pageOption.addClass('page-option')
        } else {
          $pageOption.addClass('unavailable')
        }

        $pageOptions.append($pageOption)
      })
      var $pageNext = $('<li class="arrow"></li>')
      $pageNext.html('<a>' + '&raquo;' + '</a>')
      var nextOffset = (currentPage * limit)
      if (nextOffset <= total) {
        $pageNext.data('offset', nextOffset)
        $pageNext.addClass('page-option')
      }
      $pageOptions.append($pageNext)
      return $pageOptions
    }

    function simplePageList (totalPages) {
      var array = []
      for (let i = 0; i < totalPages; ++i) {
        array.push(i + 1)
      }

      return array
    }

    function getPageOptions (currentPage, pagesShown, firstN, lastN, totalPages, truncateCharacter) {
      var pageStart = 0
      var pageEnd = 0

      pageStart = Math.ceil(currentPage - pagesShown / 2)
      pageEnd = pageStart + pagesShown - 1

      if (totalPages <= pagesShown + firstN + lastN) {
        return simplePageList(totalPages)
      }

      // Slide window forward because it's off the set to the left
      if (pageStart <= lastN) {
        pageStart = lastN
        pageEnd = lastN + pagesShown
      }

      // Slide window backward because it's off the set to the right
      if (pageEnd > totalPages) {
        pageStart -= (pageEnd - totalPages)
        pageEnd = totalPages
      }

      var items = []

      // Output the preroll items

      for (let i = 1; i <= firstN; ++i) {
        items.push(i)
      }

      // Truncate after preroll

      if (firstN < pageStart) {
        items.push(truncateCharacter)
      }

      // Output the main run

      for (let i = Math.max(pageStart, firstN + 1); i <= Math.min(pageEnd, totalPages - lastN); ++i) {
        items.push(i)
      }

      // Truncate before post-roll

      if (pageEnd < totalPages - lastN) {
        items.push(truncateCharacter)
      }

      // Output the post-roll items
      for (let i = totalPages - lastN + 1; i <= totalPages; ++i) {
        items.push(i)
      }

      return items
    }
  })

  function updateCollapsers () {
    var collapsers = $('.data-view table tr th a.collapser')

    collapsers.each(function () {
      var collapserId = $(this).parent('th').attr('data-collapser')
      var collapsedElements = $('tr').find("[data-collapser-parent='" + collapserId + "']")

      if ((collapserId in expandedArray) && (expandedArray[collapserId] === true)) {
        $(this).html('<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>')
        $(collapsedElements).each(function () {
          $(this).removeClass('hidden')
        })
      } else {
        $(this).html('<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>')
        $(collapsedElements).each(function () {
          $(this).addClass('hidden')
        })
      }
    })
  }

  function reflowFoundation () {
    $(document).foundation('dropdown', 'reflow')
  }

  function addContentEditable () {
    if ($('td[data-column="s11.score"]:not(:has(div))').length > 0) {
      $('td[data-column="s11.score"]:not(:has(div))').each(function (i) {
        $(this)
          .prop('contenteditable', true)
          .prop('tabindex', i + 1)
          .css('transition', 'box-shadow .3s')
          .css('max-width', '200px')
          .css('overflow-wrap', 'break-word')
          .mouseenter(function () {
            $(this).css('box-shadow', 'inset 0px 0px 1px 1px #aaa')
          })
          .mouseleave(function () {
            $(this).css('box-shadow', 'none')
          })
          .keyup(function (e) {
            if (e.keyCode == 9 || e.keyCode == 16) { return }
            if (!$(this).hasClass('editing')) {
              $(this).addClass('editing').focusout(saveNotes)
            }
          })
      })
    }
  }

  function saveNotes () {
    var element = $(this)
    var appId = $(this).parent().find('td[data-column="a.id"]').text()
    var cycleId = window.location.href.match(/.*cycles\/(\d*)\/.*$/)[1]
    var action = 'api/cycle/' + cycleId + '/applicant/' + appId + '/module/11/update'
    var text = element.html().trim()
    element.append(' <span class="loading" style="color: #2980b9; font-size: 1.2em"><i class="fa fa-spinner fa-pulse fa-fw fa-1x"></i></span>')
    element.prop('contenteditable', false)
    element.removeClass('editing')
    $.post(action, { score: text }, function (data) {
      if (!Object.prototype.hasOwnProperty.call(data, 'error')) {
        element.html(data.score)
      } else {
        alert(data.error)
      }
      element.find('.loading').remove()
      element.unbind('focusout')
      element.prop('contenteditable', true)
    })
  }
})

$(document).on('dataview:expand', function () {
  $('*[data-expanded-only]').removeClass('hidden')
})

$(document).on('dataview:collapse', function () {
  $('*[data-expanded-only]').addClass('hidden')
})
