/* eslint no-var: 0 */
/* eslint no-new: 0 */
import $ from 'jquery'

export default class ApplicantCard {
  init (simple = false) {
    this.widget = $('#applicant-card')
    this.simple = simple
    this.applicantId = null
    this.bindUIActions()
    this.ajaxRequest = null
    this.cycleId = $('#applicant-card').data('cycle-id')
  }

  bindUIActions () {
    const self = this

    window.addEventListener('appcard:close', () => {
      self.close()
    })
  }

  selectApplicant (id) {
    const self = this

    if (id === self.applicantId && self.isOpen()) {
      self.close()
      return false
    } else {
      if (id === self.applicantId) {
        self.open()
        self.widget.fadeIn(150)
        return true
      }
      self.applicantId = id
      self.widget.find('div#applicant-view').fadeOut(150)
      self.open()
    }
    self.widget.attr('data-applicant-id', id)

    const event = new CustomEvent('appcard:opened', { detail: { id } })
    window.dispatchEvent(event)
    return true
  }

  open () {
    this.widget.removeClass('closed')
    this.widget.addClass('open')
    $(document).trigger('appcard:open')
  }

  close () {
    this.widget.removeClass('open')
    this.widget.addClass('closed')
  }

  isOpen () {
    return this.widget.hasClass('open')
  }
}
