import axios from 'axios'

export function getTemplates (params) {
  return axios({
    method: 'get',
    url: '/api/v1/templates',
    params: params
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function getTemplate (id) {
  return axios({ method: 'get', url: `/api/v1/templates/${id}` })
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function getCancellableTemplate (id, signal) {
  return axios({
    method: 'get',
    url: `/api/v1/templates/${id}`,
    signal: signal
  })
    .then(response => response.data)
    .catch(err => {
      if (!axios.isCancel(err)) {
        console.error('Error fetching template.', err)
        throw err
      } else {
        console.info('Cancelled fetching template', err)
      }
    })
}

export function createTemplate (json) {
  return axios({
    method: 'post',
    url: '/api/v1/templates',
    data: json
  })
    .then(() => true)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function editTemplate (id, json) {
  return axios({
    method: 'post',
    url: `/api/v1/templates/${id}`,
    data: json
  })
    .then(() => true)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function deleteTemplate (id) {
  return axios({
    method: 'delete',
    url: `/api/v1/templates/${id}`
  })
    .then(() => true)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function getCategories (limit) {
  return axios({ method: 'get', url: '/api/v1/templates/categories' + (limit !== undefined ? `?limit=${limit}` : '') })
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw err
    })
}
